<template>
  <ul 
    v-if="tagList.length > 0 || $slots.default" 
    ref="tagListContainer"
    class="product-card__service-tag-list"
    :class="{
      'wrap': config.disableSlicingTagList
    }"
  > 
    <slot></slot>
    
    <!-- 初始化第一个不隐藏 占位 -->
    <li 
      v-for="(tagItem, index) in tagList"
      :key="index"
      class="product-card__service-tag-item"
      :class="{ 'initialized': !initialized && index > 0 }"
    >
      <ProductCardSheinClubDiscount
        v-if="tagItem.key === 'sheinClubLabel'"
        type="smallNew"  
        :discount-value="tagItem.value"
      />
      <ProductCardLabelsText
        v-else
        :text-data="tagItem.textData" 
        :ellipsis="tagItem.ellipsis"
        :width="tagItem.width"
      />
    </li>
  </ul>
</template>

<script name="ProductCardServiceTag" setup>
import { template } from '@shein/common-function'
import ProductCardLabelsText from './ProductCardLabelsText.vue'
import ProductCardSheinClubDiscount from '../ProductCardPrice/ProductCardSheinClubDiscount.vue'
import { ref, inject, onMounted, watch, nextTick } from 'vue'
import { checkSlicingDomList } from '../../utils/index'

/**
 * 计算宽度是否能在一行展示，不够则截断
 */
const checkSlicingTagList = () => {
  const containerDom = tagListContainer.value
  const { width } = containerDom?.getBoundingClientRect?.() || {}
  if (typeof width == 'undefined') return
  const tagListDom = containerDom.querySelectorAll('.product-card__service-tag-item')
  const { showIndexList = [], remainingWidth } = checkSlicingDomList(width, tagListDom)
  // 如果还有超过100px剩余宽度，则再将一个插入并将不够的显示。。。
  let lastEllipsis = false
  if (remainingWidth > 100 && showIndexList.length !== tagList.value) {
    const lastIndex = tagList.value.findIndex((item, index) => {
      return !showIndexList.includes(index)
    })
    if (lastIndex !== -1) {
      showIndexList.push(lastIndex)
      lastEllipsis = true
    }
  }
  if (showIndexList.length) {
    tagList.value = showIndexList.map((item, index) => {
      // 最后一个加入省略显示
      if (lastEllipsis && tagList.value.length - 1 === index) {
        tagList.value[item].ellipsis = true
        tagList.value[item].width = `${remainingWidth - 5}px`
      } else {
        tagList.value[item].ellipsis = false
        tagList.value[item].width = ''
      }
      return tagList.value[item]
    })
  } else {
    tagList.value = []
  }
}

const config = inject('config', {})
const labelsFromKey = inject('labelsFromKey')
const setAttrForAnalysis = inject('setAttrForAnalysis')

const goodsInfo = inject('goodsInfo', () => ({}))

// 付费会员标签
const getSheinClubLabel = () => {
  const { 
    suggestedSalePriceConfigInfo: { 
      config: { hideTargetBySuggestedSalePrice } = {},
    } = {},
    showSheinClubDisount
  } = goodsInfo.value?.pretreatInfo || {}
  const { showSheinClubLabelInServicesLabel } = config
  const sheinClubDiscountValue = goodsInfo.value.sheinClubPromotionInfo?.discountValue || ''
  // 付费会员标签放在第一个【多重判断：合规站点、abt、是否有discountValue等】
  const showNewSheinClubLabel = !!(showSheinClubDisount && sheinClubDiscountValue && showSheinClubLabelInServicesLabel && !hideTargetBySuggestedSalePrice)
  return showNewSheinClubLabel ? [{ key: 'sheinClubLabel', value: sheinClubDiscountValue }] : []
}

const initTextTag = () => {
  const { bffGoodsInfo, pretreatInfo } = goodsInfo.value
  const { 
    showLocalSeller, 
    showQuickShip, 
    showBadge,
    hidePromotionLabel
  } = config
  const { serviceLabels } = pretreatInfo?.[labelsFromKey] ?? {}
  const textTag = []
  const anaText = []
  if (!serviceLabels?.length) return textTag
  // 服务类标签
  serviceLabels.forEach(({
    localMall,
    quickship,
    promotionLabel,
    universalLabel
  }) => {
    // 本地卖家
    if (showLocalSeller && localMall?.labelLang) {
      textTag.push(textDataTag({ text: localMall.labelLang, className: 'QuickShip' }))
      anaText.push(localMall.labelLangEn)
    }
    // 快速发货
    if (showQuickShip && quickship?.labelLang) {
      const { newStyle, italicText, suiIcon, appTraceInfo } = quickship || {}
      textTag.push(textDataTag({ 
        text: quickship.labelLang, 
        className: newStyle ? 'QuickShipNew' : 'QuickShip', 
        useItalic: italicText,
        suiIcon: template('14px', suiIcon)
      }))
      anaText.push(appTraceInfo || quickship.labelLangEn)
    }
    // 活动
    if (!hidePromotionLabel && promotionLabel) {
      textTag.push(textDataTag({ text: promotionLabel, className: 'Activity' }))
      anaText.push(promotionLabel)
    }
    // tsp角标 通用角标
    if (showBadge && universalLabel?.length) {
      universalLabel.forEach(({ appTraceInfo, fontColor, backgroundColor, labelId, icon, labelLang, labelLangEn }) => {
        const style = {
          color: fontColor ?? '',
          backgroundColor: backgroundColor ?? ''
        }
        textTag.push(textDataTag({
          text: labelLang, 
          className: labelLangEn?.replace(/\s/g, ''), 
          style, 
          icon
        }))
        anaText.push(appTraceInfo || `${labelId}_${labelLangEn}`)
      })
    }
  })
  if (textTag.length > 0) {
    setAttrForAnalysis((el) => {
      const anaPrefix = bffGoodsInfo ? '' : 'show_service_label_'

      el.setAttribute('data-service-label', `${anaPrefix}${anaText.join('_')}`.replace(/\s+/g, '_'))
    })
  }
  return textTag
}

const textDataTag = ({ text, className = '', style = {}, icon = '', useItalic = false, suiIcon = '' }) => {
  return {
    key: 'text',
    textData: {
      text,
      className,
      style,
      icon,
      useItalic,
      suiIcon,
      // 是否固定宽度，文字超出省略点点点
      ellipsis: false,
      width: false
    }
  }
}

const initTagList = () => [...getSheinClubLabel(), ...initTextTag()]

const slicingTagList = async () => {
  if (config.disableSlicingTagList) return
  if (tagList.value.length > 0) {
    await nextTick()
    checkSlicingTagList()
    await nextTick()
  }
  initialized.value = true
}

const tagListContainer = ref(null)
const initialized = ref(config.disableSlicingTagList)
const tagList = ref(initTagList())

watch(
  () => goodsInfo.value.goods_sn,
  () => {
    initialized.value = config.disableSlicingTagList
    tagList.value = initTagList()
    slicingTagList()
  },
)

onMounted(() => {
  slicingTagList()
})

</script>

<style lang="less" scoped>
.initialized {
  opacity: 0;
}
.product-card__service-tag-list {
  margin-top: 6px;

  .flexbox();
  .align-center();

  flex-wrap: nowrap;
  overflow: hidden;

  &.wrap {
    flex-wrap: wrap;
    .product-card__service-tag-item  {
      margin-bottom: 4px;
    }
  }
}
.product-card__service-tag-item {
  flex: 0 0 auto;
  margin-right: 4px;
}
</style>
