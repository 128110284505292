<template>
  <div
    :class="[
      'product-card__color-item',
      {
        'lazyload': !!colorImage,
      },
    ]"
    :style="[
      {
        zIndex: 3 - index,
      },
    ]"
    :data-background-image="transformImg({ img: colorImage })"
  >
  </div>
</template>

<script>
import { transformImg } from '@shein/common-function'
export default {
  name: 'ProductCardColorItem',
  props: {
    colorImage: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  methods: {
    transformImg
  },
}
</script>

<style lang="less">
.product-card__color-item {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 0.5px solid #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
</style>
