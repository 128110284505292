<template>
  <section
    class="S-product-card__wrapper-bottom"
    :class="{'S-product-card__wrapper-similar': showSimilarPopup}"
  >
    <!-- 图片底部slot -->
    <slot name="imgBottom"></slot>

    <!-- 主图右下角 -->
    <ProductCardImgRightBottom
      :belt-label="beltPriceCtrl.beltLabel"
      :related-color="relatedColor"
      :get-corner-property-upsell="getCornerPropertyUpsell"
      :sale-attr-label="saleAttrLabel"
    />

    <!-- 低价爆品 > 剩余库存 例子：only xx left > coming soon > 售罄 -->
    <template
      v-for="(infoItem, idx) in labelInfo"
      :key="idx"
    >
      <ImgBottomLabel
        :type="infoItem.type"
        :show-similar-popup="showSimilarPopup"
        :center="infoItem.center"
        :label-icon="infoItem.icon"
        :label-text="infoItem.text"
        :line-camp="infoItem.lineCamp"
      />
    </template>

    <!-- 腰带 -->
    <ImgBottomBeltLabel 
      v-if="beltPriceCtrl.beltLabel"
      :belt-label="beltPriceCtrl.beltLabel"
    />

    <!-- 例如: This Item -->
    <!-- 图片底部slot(盖在腰带之上) -->
    <slot name="imgBottomInTop"></slot>
  </section>
</template>

<script>
import ImgBottomLabel from './ImgBottomLabel.vue'
import ImgBottomBeltLabel from './ImgBottomBeltLabel.vue'
import ProductCardImgRightBottom from '../ProductCardImgRightBottom/index.js'

import { views } from '../../utils/constant'

export default {
  name: 'ProductCardImgBottom',
  components: {
    ImgBottomLabel,
    ImgBottomBeltLabel,
    ProductCardImgRightBottom
  },
  props: {
    locateLabelsCtrl: {
      type: Object,
      default: () => ({
        needShowOldLabel: false,
        upperLeftVisible: false,
        upperRightVisible: false,
        lowerLeftVisible: false,
        lowerRightVisible: false,
      }),
    },
    imgBottomLeft: {
      type: [Object, null],
      default: null,
    },
    saleout: {
      type: [Object, null],
      default: null,
    },
    showSimilarPopup: {
      type: Boolean,
      default: false,
    },
    beltPriceCtrl: {
      type: Object,
      default: () => ({}),
    },
    imgDesignWidth: {
      type: [String, Number],
      default: 0,
    }
  },
  inject: {
    config: {
      default: () => ({}),
    },
    cutImg: {
      from: 'cutImg',
      default: (url) => url,
    },
    labelsFromKey: {
      default: ''
    },
    goodsInfo: {
      default: () => ({})
    },
    language: {
      default: () => ({})
    }
  },
  computed: {
    relatedColor () {
      const { materialValueKey, relatedColor, relatedColorCount } = this.goodsInfo.value?.[this.labelsFromKey]?.locateLabels?.LOWER_RIGHT_LABEL || {}

      return materialValueKey === 'color_information' 
        ? {
          data: relatedColor || [],
          count: relatedColorCount
        } 
        : {}
    },
    getCornerPropertyUpsell () {
      const { cornerPropertyUpsell } = this.goodsInfo.value?.[this.labelsFromKey]?.locateLabels?.LOWER_RIGHT_LABEL || {}

      return cornerPropertyUpsell
    },
    comingSoon() {
      return +this.goodsInfo.value?.new_product_unsale === 1
    },
    stockLeft() {
      return this.goodsInfo.value.pretreatInfo?.stockLeft
    },
    // shein 库存逻辑
    showStockLeft() {
      return this.stockLeft && this.config.showLeftStockShein
    },
    saleOutText() {
      // 非单列商卡显示腰带售罄样式
      const notSingleRowCard = views.LIST_PC_BROADWISE_VIEW !== this.labelsFromKey
      return notSingleRowCard && this.saleout?.show && this.saleout?.message
    },
    labelInfo() {
      const showBestDeal = this.beltPriceCtrl.showBestDeal
      const followLabelVisible = this.beltPriceCtrl.followLabelVisible
      const beltInfo = {
        type: ['orange', '12PX'],
        icon: 'sh_pc_sui_icon_lowprice_12px_fff',
        text: showBestDeal ? this.language.SHEIN_KEY_PC_25736 : this.language.SHEIN_KEY_PC_27061,
        lineCamp: showBestDeal ? 2 : 1,
        center: true
      }
      const stockLeftInfo = {
        type: ['black', '16PX'],
        text: this.stockLeft,
        center: true
      }
      // 保留款 > 剩余库存 > coming soon > 售罄
      // 保留款 > 跟价款
      if (showBestDeal) {
        return [beltInfo]
      }
      if (followLabelVisible && this.showStockLeft) {
        return [stockLeftInfo, beltInfo]
      }
      if (followLabelVisible) {
        return [beltInfo]
      }
      if (this.showStockLeft) {
        return [stockLeftInfo]
      } else if (this.config?.specialSoldOutLabel ?  this.saleOutText && this.showSimilarPopup : this.saleOutText) {
        const info = {
          type: ['black', '16PX'],
          text: this.saleOutText,
          center: true
        }
        if (this.showSimilarPopup) {
          info.icon = 'sh_pc_sui_icon_soldout_36px_fff'
        }
        if (this.comingSoon) {
          info.text = this.language.SHEIN_KEY_PC_17424
        }

        return [info]
      }

      return []
    },
    // pmsc价格倒挂
    inversionOnPmsc() {
      return Number(this.goodsInfo.value?.isInversion) === 1
    },
    saleAttrLabel() {
      return this.goodsInfo.value?.pretreatInfo?.saleAttr?.label ?? ''
    }
  },
}
</script>

<style lang="less">
.S-product-card {
  &__wrapper-similar {
    top: 0;
  }
  &__wrapper-bottom {
    position: absolute;
    bottom: 0;
    .left(0);
    .right(0);
    .wrapper-bottom {
      &__series {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: auto;
      }

      &__badge {
        display: inline-block;
        min-width: 48px;
        line-height: 22px;
        padding: 0 5px;
        .margin-l(6px);
        margin-bottom: 6px;
        text-align: center;
        font-size: 12px;
      }

      &__line {
        width: 100%;
        background: rgba(153, 153, 153, 0.6);
        text-align: center;
        color: #fff;
        font-size: 12px;

        /*sh:begin*/
        height: 22px;
        line-height: 22px;
        /*rw:begin*/
        height: 25px;
        line-height: 25px;
        background: rgba(255, 255, 255, 0.6);
        color: #333;

        & > img {
          max-width: 100%;
          max-height: 100%;
          vertical-align: baseline;
        }
      }
    }
  }
}
</style>
