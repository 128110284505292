<template>
  <div class="S-product-card__imgbottom-belt">
    <component
      :is="whichComponent.component"
      v-if="whichComponent.visible"
      ref="compRef"
      v-bind="whichComponent.props"
    />
  </div>
</template>

<script setup>
import {
  computed,
  defineAsyncComponent,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  inject
} from 'vue'
import { template } from '@shein/common-function'
import { timerEventBus, getDataTime } from '../../utils/timerEventBus.js'

const BlackFridayBelts = defineAsyncComponent(() =>
  import('./BlackFridayBelts.vue')
)
const FlashSaleBelts = defineAsyncComponent(() =>
  import('./FlashSaleBelts.vue')
)
const SpecialShapedBelt = defineAsyncComponent(() =>
  import('./SpecialShapedBelt.vue')
)
const NormalBelts = defineAsyncComponent(() => import('./NormalBelts.vue'))

const VoucherBelts = defineAsyncComponent(() => import('./VoucherBelts.vue'))

const props = defineProps({
  beltLabel: {
    type: Object,
    default: () => ({})
  },
  half: {
    type: Boolean,
    default: false
  }
})

const language = inject('language', () => ({}))

const item = inject('item', () => {})
const finalPriceAndDiscountInfo = inject('finalPriceAndDiscountInfo', () => {})


const data = reactive({
  timeId: null,
  dynamicCountDown: '00:00:00',
  currentTime: 0
})

const whichComponent = computed(() => {
  const discountPrice = finalPriceAndDiscountInfo.value.finalDiscount?.discountPrice || ''

  // 首金腰带
  if (props.beltLabel?.isVoucherBelt) {
    return {
      component: VoucherBelts,
      props: reactive({
        beltLabel: props.beltLabel,
        language: language,
      }),
      visible: true
    }
  }
  
  if (specialShapedBelt.value) {
    return {
      component: SpecialShapedBelt,
      props: {
        beltLabel: props.beltLabel,
        discountPrice: discountPrice,
        discountText: discountText.value
      },
      visible: !!discountPrice
    }
  } else if (isFlashSaleBelt.value) {
    return {
      component: FlashSaleBelts,
      props: {
        dynamicCountDown: data.dynamicCountDown,
        savedPrice: savedPrice.value
      },
      visible: isCountdownBelt.value && discountPrice
    }
  } else if (isBlackFridayBelt.value) {
    return {
      component: BlackFridayBelts,
      props: {
        bgImg: props.beltLabel.transparentImage,
        shapedImg: props.beltLabel.bgImage,
        styles: blackFridayBeltStyles.value,
        timerIcon: timerIcon.value,
        dynamicCountDown: data.dynamicCountDown,
        blackFridayBeltText: blackFridayBeltText.value,
        isCountdownBelt: isCountdownBelt.value
      },
      visible: countdownBelt.value ? !!data.dynamicCountDown : discountPrice
    }
  } else {
    return {
      component: NormalBelts,
      props: {
        beltLabel: props.beltLabel,
        half: props.half,
        styles: styles.value,
        labelText: labelText.value,
        timerIcon: timerIcon.value,
        dynamicCountDown: data.dynamicCountDown,
        isCountdownBelt: isCountdownBelt.value
      },
      visible: countdownBelt.value ? !!data.dynamicCountDown : true
    }
  }
})

const styles = computed(() => {
  return {
    color: props.beltLabel.fontColor
  }
})
const isCountdownBelt = computed(() =>
  Boolean(countdownBelt.value && data.dynamicCountDown)
)
const discountBelt = computed(() => props.beltLabel.component == 'discount')
const countdownBelt = computed(() =>
  ['countdown', 'countdownAbShaped', 'flash_purchase_belt'].includes(
    props.beltLabel.component
  )
)
const specialShapedBelt = computed(
  () => props.beltLabel.component == 'priceDiscount'
)
const isBlackFridayBelt = computed(() =>
  [
    'countdownAbShaped',
    'discountAbShaped',
    'willSaveAbShaped',
    'willSave'
  ].includes(props.beltLabel.component)
)
const isFlashSaleBelt = computed(
  () => props.beltLabel?.component == 'flash_purchase_belt'
)
// 折扣率文案
const discountText = computed(() => {
  if (!props.beltLabel.estimatedLabelLang) return ''

  // bff数据直接返回，已处理好
  if (item.value.bffGoodsInfo) {
    return props.beltLabel.estimatedLabelLang
  }

  // unitDiscountString 的逻辑包含 到手价折扣率，付费会员折扣率，新型闪购折扣率，常规售价折扣率
  const unitDiscountString = finalPriceAndDiscountInfo.value.finalDiscount?.unitDiscountString || ''

  if (unitDiscountString) {
    return template(
      unitDiscountString?.replace('-', ''),
      props.beltLabel.estimatedLabelLang
    )
  }

  return ''
})
const discountPriceText = computed(() => {
  if (!props.beltLabel.estimatedLabelLang) return ''

  // discountPrice 的逻辑包含 到手价可省金额，付费会员可省金额，新型闪购可省金额，常规售价可省金额
  const discountPrice = finalPriceAndDiscountInfo.value.finalDiscount?.discountPrice || ''
  if (discountPrice) {
    return template(discountPrice, props.beltLabel.estimatedLabelLang)
  }

  return ''
})
// 折扣腰带文案
const labelText = computed(() => {
  if (discountBelt.value && discountText.value) {
    return discountText.value
  }

  if (discountBelt.value) {
    return props.beltLabel.labelLang
  }

  return ''
})
const savedPrice = computed(() => {

  // discountPrice 的逻辑包含 到手价可省金额，付费会员可省金额，新型闪购可省金额，常规售价可省金额
  const savedPriceText = finalPriceAndDiscountInfo.value.finalDiscount?.discountPrice || ''

  const { isSpecialDeSuggested, suggestedSalePriceData } = finalPriceAndDiscountInfo.value || {}
  if (isSpecialDeSuggested && suggestedSalePriceData?.showRetailPrice) {
    return props.beltLabel?.retailDiscountPrice?.amountWithSymbol ?? ''
  }

  return savedPriceText
})
const timerIcon = computed(() => props.beltLabel.icon)
const endTime = computed(() => props.beltLabel.endTime || 0) // return 1681565350000
const blackFridayBeltStyles = computed(() => {
  return {
    ...styles.value,
    fontSize: '13px'
  }
})
const blackFridayBeltText = computed(() => {
  const isSaveLabelLang = ['willSaveAbShaped', 'willSave'].includes(
    props.beltLabel.component
  )
  const isDiscountLabelLang = ['discountAbShaped'].includes(
    props.beltLabel.component
  )

  if (isBlackFridayBelt.value && isSaveLabelLang) {
    if (discountPriceText.value) {
      return discountPriceText.value
    }

    return props.beltLabel.labelLang
  }

  if (isBlackFridayBelt.value && isDiscountLabelLang) {
    if (discountText.value) {
      return discountText.value
    }

    return props.beltLabel.labelLang
  }

  return ''
})

const compRef = ref(null)
const handleTimeout = () => {
  if (!compRef.value) {
    return
  }
  data.currentTime = data.currentTime + 1000 // 毫秒
  const seconds = endTime.value - data.currentTime
  data.dynamicCountDown = getDataTime(seconds)
  if (!data.dynamicCountDown) {
    timerEventBus.remove(data.timeId)
  }
}

onMounted(() => {
  if (!countdownBelt.value) return
  data.currentTime = props.beltLabel.currentTime || Date.now()
  // data.currentTime = 1681565340000
  if (endTime.value < data.currentTime) return
  data.timeId = timerEventBus.useState(handleTimeout)
})

onUnmounted(() => {
  if (!countdownBelt.value) return
  timerEventBus.remove(data.timeId)
})
</script>

<style lang="less">
.S-product-card {
  &__imgbottom-belt {
    position: relative;
    width: 100%;
    > img {
      width: 100%;
      height: auto;
    }
    .imgbottom-belt {
      &__text-wrap {
        .flexbox();
        .align-center();
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        font-size: 0;
      }
      &__text {
        .text-overflow();
        // margin-left: 33.333%;
        margin-left: 50%;
        padding-right: 4px;
        line-height: 14px;
        font-size: 12px;
        flex: 1;
        text-align: center;
        font-weight: 700;
        &_half {
          margin-left: 50%;
        }

        > img {
          margin-right: 2px;
          width: 16px;
          height: 16px;
          vertical-align: middle;
        }

        > span {
          vertical-align: middle;
        }
      }
    }

    /* 黑色星期五相关腰带 */
    .blackfridaybelts-content {
      font-weight: 700;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-height: 1;
    }
    .blackfridaybelts-content > img {
      width: 16px;
      height: 16px;
      margin-bottom: 2px;
    }
  }
}
</style>
