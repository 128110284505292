<template>
  <a
    ref="imgContainer"
    :class="[
      'S-product-card__img-container',
      'j-expose__product-item-img',
      {
        'S-product-card__img-container_mask': showImgMask
      },
    ]"
    :href="forbidJumpDetail ? 'javascript:void(0);' : goodsDetailUrl"
    tabindex="0"
    :aria-label="goodsName"
    :target="openTarget"
    @contextmenu="showMenu"
    @mouseenter="mainGoodsImgHover"
    @mouseleave="mainGoodsImgHoverLeave"
    @click="clickItem"
    @focus="mainGoodsImgFocus"
  >
    <!-- 主图 -->
    <CropImageContainer
      v-if="!relateColorInfo"
      :fixed-ratio="cropRate"
      :img-src="mainImg"
      :img-thumb="goodsInfo.value.goods_img_thumb"
      :is-support-crop-image="isSupportCropImage"
      :transform-img-props="{
        imgClassName: `fsp-element ${compSrc ? `fsp__${compSrc}` : ''}`,
        isFirstPage: noImgLazy,
        usePicture: false,
        loadImg: lazyImg,
        imgDesignWidth: productDesignWidth,
        alt: goodsName,
        imgDataExp
      }"
      @img-load-succ="handleImgLoad('succ')"
      @img-load-fail="handleImgLoad('fail')"
    />
    <!-- 多颜色切换 首图不需要懒加载 -->
    <CropImageContainer
      v-else
      :fixed-ratio="cropRate"
      :is-support-crop-image="isSupportCropImage"
      :img-src="transformImgFn({ img: mainImg })"
      :transform-img-props="{
        isFirstPage: true,
        alt: goodsName,
        imgDataExp
      }"
      @img-load-succ="handleImgLoad('succ')"
      @img-load-fail="handleImgLoad('fail')"
    />

    <!-- 主图蒙层 -->
    <ProductCardMaskLayer
      v-show="!showSecondImg"
      :goods-info="goodsInfo"
      :main-img="mainImg"
      :crop-rate="cropRate"
      :img-design-width="productDesignWidth"
    />

    <slot name="imgContent"></slot>
    
    <ClientOnly>
      <!-- 二图 -->
      <div :class="['S-product-card__img-submain', showSecondImg ? 'image-fade-in' : 'image-fade-out']">
        <CropImageContainer
          v-if="secondImg"
          :fixed-ratio="cropRate"
          :is-support-crop-image="isSupportCropImage"
          :img-src="secondImg"
          :transform-img-props="{
            isFirstPage: false,
            usePicture: false,
            loadImg: lazyImg,
            imgDesignWidth: productDesignWidth,
            alt: goodsName,
            imgDataExp
          }"
          @img-load-succ="handleImgLoad('succ')"
          @img-load-fail="handleImgLoad('fail')"
        />
      </div>
    
      <product-card-locate-labels
        v-if="locateLabelsCtrl.visible"
        :locate-labels="locateLabels"
        :img-design-width="productDesignWidth"
      />

      <!-- 图片右上角 品牌，收藏数，选择input -->
      <product-card-img-top-right
        ref="imgTopRight"
        :locate-labels-ctrl="locateLabelsCtrl"
        :show-select="showSelect"
        :select-position="selectPosition"
        :select-status="selectStatus"
        :select-disable="selectDisable"
        :img-design-width="productDesignWidth"
        @select="handleSelect"
      />
    </ClientOnly>

    <!-- 底部横向 (左下角, 右下角，横向) -->
    <ProductCardImgBottom
      :locate-labels-ctrl="locateLabelsCtrl"
      :img-bottom-left="imgBottomLeft"
      :saleout="saleout"
      :show-similar-popup="showSimilarPopup"
      :img-design-width="productDesignWidth"
      :belt-price-ctrl="beltPriceCtrl"
    >
      <!-- 图片底部slot -->
      <template #imgBottom>
        <slot name="imgBottom"></slot>
      </template>

      <!-- 图片底部slot(盖在腰带之上) -->
      <template #imgBottomInTop>
        <slot name="imgBottomInTop"></slot>
      </template>
    </ProductCardImgBottom>
  </a>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { ClientOnly } from '@sheinfe/vue-client-only'
import { transformImg as transformImgFn } from '@shein/common-function' 
import { CropImageContainer } from 'public/src/pages/components/CropImageContainer/index.js'
import ProductCardImgBottom from './ProductCardImgBottom.vue'
import { ProductCardMaskLayer } from '../ProductCardMaskLayer'
import { reportMetricCount } from  '../../utils'

export default {
  name: 'ProductCardImgs',
  inject: ['config', 'isSupportCropImage', 'goodsInfo'],
  components: {
    ClientOnly,
    CropImageContainer,
    ProductCardMaskLayer,
    ProductCardLocateLabels: defineAsyncComponent(() => import('./ItemLocateLabels.vue')),
    ProductCardImgTopRight: defineAsyncComponent(() => import('./ProductCardImgTopRight.vue')),
    ProductCardImgBottom
  },
  props: {
    locateLabels: {
      type: Object,
      default: () => ({}),
    },
    locateLabelsCtrl: {
      type: Object,
      default: () => ({
        upperLeftVisible: false,
        upperRightVisible: false,
        lowerLeftVisible: false,
        lowerRightVisible: false,
      }),
    },
    showSelect: {
      type: Boolean,
      default: false,
    },
    selectPosition: {
      type: String,
      default: 'imgRight' // 添加默认值
    },
    selectStatus: {
      type: Boolean,
      default: false,
    },
    selectDisable: {
      type: Boolean,
      default: false,
    },
    showSimilarPopup: {
      type: Boolean,
      default: false,
    },
    forbidJumpDetail: {
      type: Boolean,
      default: false,
    },
    relateColorInfo: {
      type: [Object, null],
      default: () => ({}),
    },
    lazyImg: {
      type: String,
      default: '',
    },
    // 窗口打开类型
    openTarget: {
      type: String,
      default: '_self',
    },
    showSpuImg: {
      type: Boolean,
      default: false,
    },
    goodsDetailUrl: {
      type: String,
      default: ''
    },
    beltPriceCtrl: {
      type: Object,
      default: () => ({}),
    },
    productDesignWidth: {
      type: [String, Number],
      default: 0,
    },
    // @example '1-1'
    cropRate: {
      type: String,
      default: '3-4',
    },
    noImgLazy: {
      type: Boolean,
      default: false  
    },
    reportMetrics: {
      type: Object,
      default: () => ({})
    },
    compSrc: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      showSecondImg: false,
    }
  },
  computed: {
    // 展示图片蒙层
    showImgMask() {
      return this.config.showImgMask
    },
    spuImg() {
      return ((this.goodsInfo.value?.spu_image || [])[0]?.medium_image) || ''
    },
    mainImg() {
      return this.showSpuImg ? this.spuImg : this.goodsInfo.value?.goods_img || ''
    },
    // getCombinedInfo的detail_image是个对象数组，如果前置格式化数据，商品数量多时消耗过多性能，故在这里兼容一下取值
    secondImg() {
      return this.goodsInfo.value?.detail_image?.[0]?.medium_image || this.goodsInfo.value?.detail_image?.[0] || ''
    },
    /** 图片左下角角标 new top rated 内容其实是配置的，后期可以随意新增 */
    imgBottomLeft() {
      return this.goodsInfo.value?.pretreatInfo?.imgBottomLeft || null
    },
    seriesLogo() {
      return this.goodsInfo.value?.pretreatInfo?.seriesLogo || null
    },
    flashSale() {
      return this.goodsInfo.value?.pretreatInfo?.flashsale
    },
    plusSize() {
      return this.goodsInfo.value?.pretreatInfo?.plusSize || null
    },
    saleout() {
      return this.goodsInfo.value?.pretreatInfo?.saleout || { show: false }
    },
    goodsName() {
      return this.goodsInfo.value?.goods_name || ' '
    },
    imgDataExp() {
      const expObj = {}
      const { min, max, unCut, cutTo } = this.config?.imgDataExp || {}
      if (min) expObj.min = min
      if (max) expObj.max = max
      if (unCut) expObj.unCut = unCut
      if (cutTo) expObj.cutTo = cutTo
      return expObj
    }
  },
  methods: {
    showMenu(e) {
      if (this.forbidJumpDetail) {
        e.preventDefault()
      }
    },
    mainGoodsImgHover() {
      if (this.showSpuImg) return

      window.clearTimeout(window.mainGoodsHoverSetTimeout)
      window.mainGoodsHoverSetTimeout = window.setTimeout(() => {
        this.secondImg && (this.showSecondImg = true)
      }, 300)
    },
    mainGoodsImgHoverLeave() {
      window.clearTimeout(window.mainGoodsHoverSetTimeout)
      this.secondImg && (this.showSecondImg = false)
    },
    clickItem(event) {
      if (this.showSelect) this.handleSelect(event)

      this.$emit('clickItem', { event, target: this.$refs.imgContainer })
    },
    // 处理选中
    handleSelect(event) {
      this.$emit('select', event)
    },
    // 焦点事件
    mainGoodsImgFocus() {
      this.$emit('focus')
    },
    handleImgLoad(imgLoadType) {
      this.reportImgLoadMetric(imgLoadType)
    },
    // 上报图片加载监控指标
    reportImgLoadMetric(imgLoadType) {
      const isServer = typeof window === 'undefined'
      const pageName = this.config?.commonParams?.pageName
      // 图片加载成功次数累加	
      if (this.reportMetrics.img && !isServer && pageName) {
        const metricInfo = {
          status: null,
        }
        const loadImgSucc = imgLoadType === 'succ'
        const loadImgFail = imgLoadType === 'fail'
        if (loadImgSucc) {
          Object.assign(metricInfo, { status: '1' })
        } else if (loadImgFail) {
          Object.assign(metricInfo, { status: '0' })
        }
        reportMetricCount({
          metricName: 'image_request_total',
          tags: {
            page: pageName,
            status: metricInfo.status,
          },
          message: `Number of img loaded ${imgLoadType}`,
        })
      }
    },
    transformImgFn
  },
  emits: ['clickItem', 'select', 'focus'],
}
</script>

<style lang="less">
.S-product-card {
  &__img-container {
    position: relative;
    display: inline-block;
    width: 100%;
    cursor: pointer;

    &_mask::before {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      content: '';
      box-sizing: border-box;
      display: block;
      /* stylelint-disable-next-line declaration-property-value-blacklist */
      z-index: 1;
    }
  }
  &__img-submain {
    position: absolute;
    top: 0;
    left: 0 /*rtl:ignore*/;
    right: 0 /*rtl:ignore*/;
    bottom: 0;
    opacity: 1;
    transform: translate3d(0, 0, 0); /** 防止图片模糊 **/
    &.image-fade-out {
      transition: opacity 0.5s;
      opacity: 0;
    }
    &.image-fade-in {
      transition: opacity 0.5s;
      opacity: 1;
    }
  }
}
</style>
