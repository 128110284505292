<template>
  <div class="product-item__sale-attr">
    <div>{{ saleAttrLabel }}</div>
  </div>
</template>

<script>
export default {
  name: 'ProductCardSaleAttr',
  props: {
    saleAttrLabel: String,
  },
  setup(props) {
  
    return {
      ...props,
    }
  },
}
</script>

<style lang="less">
.product-item {
  &__sale-attr {
    font-size: 12px;
    line-height: 1.2;
    display: flex;
    justify-content: flex-end;
    > div {
      color: #fff;
      background: var(---sui_color_gray_alpha30, rgba(0, 0, 0, 0.30));
      padding: 2px 6px;
    }
  }
}
</style>
